<template>
  <el-container style="min-height: 98vh">
    <el-header style="height: 60px; line-height: 60px;border-bottom: 2px solid #ccc; background: #409EFF;display: flex;justify-content: space-between;color: white">
      <div style="text-align: left">
        <img src="../assets/logo.png" alt="" style="width: 40px; position: relative; top: 10px;">
        <span style="font-size: 28px; color: white; margin-left: 5px">SpringBoot+Vue前后端分离项目练习</span>
      </div>
      <el-dropdown style="width: 150px; cursor: pointer; text-align: right;color: white">
        <div style="display: flex;">
          <img :src="user.avatarUrl" alt=""
               style="width: 40px; height: 40px; border-radius: 50%; position: relative; top: 10px; right: 5px">
          <span>{{ user.nickname }}</span><i class="el-icon-arrow-down" style="margin-left: 5px"></i>

        </div>
        <el-dropdown-menu slot="dropdown" style="width: 100px; text-align: center; top: 20px">
          <el-dropdown-item style="font-size: 14px; padding: 5px 0">
            <router-link to="/password">修改密码</router-link>
          </el-dropdown-item>
          <el-dropdown-item style="font-size: 14px; padding: 5px 0">
            <router-link to="/person">个人信息</router-link>
          </el-dropdown-item>
          <el-dropdown-item style="font-size: 14px; padding: 5px 0">
            <span style="text-decoration: none" @click="logout">退出</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-header>
    <el-container>
<!--<div style="height: 80px; width: 100%"></div>-->
    <el-aside :width="sideWidth + 'px'" style="box-shadow: 2px 0 6px rgb(0 21 41 / 35%);">
      <Aside :isCollapse="isCollapse" :logoTextShow="logoTextShow" style="padding-bottom: 20px" />
    </el-aside>

    <el-container>
      <el-header style="border-bottom: 1px solid #ccc;">
        <Header :collapseBtnClass="collapseBtnClass" @asideCollapse="collapse" :user="user" />
      </el-header>

      <el-main>
<!--        表示当前页面的子路由会在 <router-view /> 里面展示-->
        <router-view @refreshUser="getUser" />
      </el-main>

    </el-container>
  </el-container>
  </el-container>
</template>

<script>

import Aside from "@/components/Aside";
import Header from "@/components/Header";

export default {
  name: 'Home',
  data() {
    return {
      collapseBtnClass: 'el-icon-s-fold',
      isCollapse: false,
      sideWidth: 200,
      logoTextShow: true,
      user: {}
    }
  },
  components: {
    Aside,
    Header
  },
  created() {
    // 从后台获取最新的User数据
    this.getUser()
  },
  methods: {
    collapse() {  // 点击收缩按钮触发
      this.isCollapse = !this.isCollapse
      if (this.isCollapse) {  // 收缩
        this.sideWidth = 64
        this.collapseBtnClass = 'el-icon-s-unfold'
        this.logoTextShow = false
      } else {   // 展开
        this.sideWidth = 200
        this.collapseBtnClass = 'el-icon-s-fold'
        this.logoTextShow = true
      }
    },
    getUser() {
      let username = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).username : ""
      if (username) {
        // 从后台获取User数据
        this.request.get("/user/username/" + username).then(res => {
          // 重新赋值后台的最新User数据
          this.user = res.data
        })
      }
    },
    logout() {
      this.$store.commit("logout")
      this.$message.success("退出成功")
    },
  }
}
</script>

<style scoped>
.el-icon-arrow-down{

  line-height: 60px;
  font-size: 20px;
}

</style>